const heroKinyarwanda = {
    searchBarTitle: "Uburyo Bworoshye kandi Bwihuse bwo Kugura cyangwa Kugurisha Imodoka",
    searchBarPlaceholder: "Shakisha imodoka wifuza",
    willingToSellYourUsedCar: "Wifuza kugurisha imodoka yawe yakoze?",
    searchByCategory: "Shakisha ugendeye ku rwego",
    whyChooseAuto: "Why Choose AUTO24?",
    searchCarByTheirBodyType: "Shakisha ugendeye ku miterere",
    searhcarbybrands: "Shakisha ugendeye ku bwoko",
    exploreCar: "Explore Cars",
    chooseCar: "Choose a Car",
    buyACar: "Buy your car",
    buyACarDesc: "Purchase it and relax, we'll take care of the rest!",
    buyACarDescs: "Buy safely and relax, we'll take care of everything!",
    weAreAroundTheArea: "Duherereye mu gace ka Rwanda",
    youcanfindusaroundthislocation: "Ushobora kudusanga hafi y'aha hantu",
    SearchByLocation: "Shakisha ukurikije ahantu",
    questionSubtitle: "Hano hari ibibazo byinshi by'abantu batandukanye ushobora kutumenyeraho",
    askQuestions: "Baza ibibazo",
    questions: "Ibibazo",
    carsellInfo: "Give us details about your vehicle",
    yourCar: "Your Car",
    valuation: "Valuation",
    valuationInfo: "We come to inspect your car at your place or you come to us",
    sellCar: "Gurisha",
    sellCarInfo: "Receive your money in less than 24 hours!",
    happycustomerHappyus: "Iyo abakiliya bishimye,natwe turishima",
    questionTextAreaPlaceHolder: "Ni ikihe kibazo wifuza kubaza?",
    discovercar: "Discover our cars",
    discovercarSubtitle: "Shakisha n'izindi modoka zacu zakoze",
    chooseACarSubtitle: "Select the car that suits you most",
    chooseACarSubtitles: "Hitamo imodoka wakunze kandi ushaka"
}

export default heroKinyarwanda;