import { Suspense, lazy, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import '../src/styles/main.scss';

import { getCarBrandsList } from './redux/actions/carBrandsAction';
import { getWishlistData } from './redux/actions/wishlistAction';
import { ICombineReducerType } from './redux/reducers';

import Cookies from 'js-cookie';
import SuspenseLoading from './components/ui/SuspenseLoading';
import { $FIXME, COUNTRY_TOP_LEVEL_DOMAIN, ROUTES } from './constants';
import i18n from './localization';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/thumbs';
import { DEFAULT_LANG, getGoogleAnalyticsId, languagesList, getTopLevelDomain } from './utils/translation';
import ReactGA from 'react-ga4';

const Home = lazy(() => import('./pages/Home'));
const DetailPage = lazy(() => import('./pages/DetailPage'));
const SellPage = lazy(() => import('./pages/SellPage'));
const Finance = lazy(() => import('./pages/Finance'));
const Insure = lazy(() => import('./pages/Insure'));
const About = lazy(() => import('./pages/About'));
const Faq = lazy(() => import('./pages/Faq'));
const ContactUs = lazy(() => import('./pages/ContactUs'));
const Listingpage = lazy(() => import('./pages/Listingpage'));
const Blogs = lazy(() => import('./pages/Blogs'));
const Account = lazy(() => import('./pages/Account'));
const Iminterested = lazy(() => import('./pages/Interest'));
const Categories = lazy(() => import('./pages/Categories'));
const Tab = lazy(() => import('./pages/Tab'));
const BlogDetails = lazy(() => import('./pages/BlogDetails'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const TermsOfUse = lazy(() => import('./pages/TermsOfUse'));
const OurTeam = lazy(() => import('./pages/OurTeam'));
const FindCarPricePage = lazy(() => import('./pages/FindCarPrice'));
const PressPage = lazy(() => import('./pages/PressPage'));
const Notfound = lazy(() => import('./pages/Notfound'));
const AccountVerification = lazy(() => import('./components/AuthFlow/AccountVerfication'));
const UnsubscribeBlog = lazy(() => import('./components/UnSubscribeBlog'));
const ForgetPasword = lazy(() => import('./components/AuthFlow/ForgotPassword'));
const WhatsAppChatBox = lazy(() => import('./components/ui/WhatssAppChatBot'));
const ProtectedRoute = lazy(() => import('./components/AuthFlow/ProtectedRoute'));
const CarFinanceForm = lazy(() => import('./pages/CarFinanceForm'));

const gaId = getGoogleAnalyticsId();
gaId && ReactGA.initialize(gaId);

function App() {
    const location = useLocation();
    const navigate = useNavigate();
    const isTablet = useMediaQuery({ query: '(min-width: 768px)' });
    const dispatch = useDispatch();
    const { isloggedIn, user } = useSelector(
        (state: ICombineReducerType) => state.auth
    );
    !Cookies.get('accessToken') && localStorage.removeItem('user');
    const { t } = useTranslation(['routes']);

    const topLevelDomain = getTopLevelDomain();

    useEffect(() => {
        gaId && ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    })

    // When user manually tries to navigate for different language
    useEffect(() => {
        const langList = languagesList();
        let isNoLangSelected = true;
        Object.values(langList).forEach((item) => {
            if(location.pathname.includes(`/${item}`)) {
                isNoLangSelected = false;
                i18n.changeLanguage(item);
            }
        });
        if(isNoLangSelected) {
            // const hasEnglishLang = Object.values(langList).some(item => item === "en")
            // i18n.changeLanguage(hasEnglishLang ? "en" : DEFAULT_LANG);
            if(i18n.language === 'en')
                i18n.changeLanguage("en");
            else
                i18n.changeLanguage(DEFAULT_LANG);
        }
    }, [])
    
    useEffect(() => {
        if (isTablet && location.pathname === t('/account')) {
            navigate(
                `${t('/account', { ns: 'routes' })}${t('/edit-profile', {
                    ns: 'routes'
                })}`
            );
        }
    }, [isTablet]);
    useEffect(() => {
        getCarBrandsList(dispatch, true);
    }, [dispatch]);
    useEffect(() => {
        if (isloggedIn) {
            getWishlistData(dispatch);
        }
    }, [isloggedIn]);
    const currentPath = useLocation();
    const translate = (url: string) => {
        return t(url, { lng: i18n.language });
    };

    useEffect(() => {
        const languagesObj = languagesList();
        const sanitize = Object.values(languagesObj).some((item) => currentPath.pathname.split('/').includes(item));
        // let sanitize = currentPath.pathname.split('/').includes('fr') || currentPath.pathname.split('/').includes('ar');
        let url;
        let resturl;
        if (sanitize) {
            url = currentPath.pathname.slice(4);
            url = url.split('/').filter((item: $FIXME) => item)[0];
            if (url) {
                resturl = currentPath.pathname.slice(4 + url.length);
            }
        } else {
            url = currentPath.pathname
                .split('/')
                .filter((item: $FIXME) => item)[0];

            url = url ? url : '';
            if (url) {
                resturl = currentPath.pathname.slice(url.length + 1);
            }
        }

        resturl
            ? navigate(t('/' + url, { lng: i18n.language }) + t(resturl))
            : navigate(t('/' + (url ? url : ''), { lng: i18n.language }));
    }, [i18n.language]);

    return (
        <Suspense fallback={<SuspenseLoading />}>
            <div className="App">
                <Routes>
                    <Route path={translate(ROUTES.home)} element={<Home />} />
                    <Route
                        path={translate(ROUTES.buyCars)}
                        element={<Listingpage />}
                    />
                    <Route
                        path={`${translate(
                            ROUTES.buy
                        )}/:brand/:model/:year/:price/:id`}
                        element={<DetailPage />}
                    />

                    <Route
                        path={translate(ROUTES.sellCar)}
                        element={<SellPage />}
                    />
                    <Route path={translate(ROUTES.finance)} element={<Finance />} />
                    <Route
                        path={translate(ROUTES.carInsurance)}
                        element={<Insure />}
                    />
                    <Route path={translate(ROUTES.about)} element={<About />} />
                    <Route path={translate(ROUTES.faqs)} element={<Faq />} />
                    <Route
                        path={translate(ROUTES.contactUs)}
                        element={<ContactUs />}
                    />
                    <Route path={translate(ROUTES.blog)} element={<Blogs />} />
                    <Route
                        path={`${translate(ROUTES.account)}/:key`}
                        element={
                            <ProtectedRoute user={user}>
                                <Account />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={`${translate(ROUTES.imInterested)}/:id`} element={<Iminterested />} />
                    <Route
                        path={`${translate(ROUTES.category)}/:id`}
                        element={<Categories />}
                    />
                    <Route path={translate(ROUTES.account)} element={<Tab />} />

                    <Route
                        path={`${translate(ROUTES.blog)}/:id`}
                        element={<BlogDetails />}
                    />
                    <Route
                        path="/user/:token"
                        element={<AccountVerification />}
                    />
                    <Route
                        path={translate(ROUTES.privacyPolicy)}
                        element={<PrivacyPolicy />}
                    />
                    <Route
                        path={translate(ROUTES.termsOfUse)}
                        element={<TermsOfUse />}
                    />
                    { topLevelDomain === COUNTRY_TOP_LEVEL_DOMAIN.asterdio && //Only for dev server
                    <Route
                        path={translate(ROUTES.ourTeam)}
                        element={<OurTeam />}
                    />
                    }
                    { topLevelDomain === COUNTRY_TOP_LEVEL_DOMAIN.asterdio && //Only for dev server
                    <Route
                        path={translate(ROUTES.findCarPrice)}
                        element={<FindCarPricePage />}
                    />
                    }
                    <Route path={translate(ROUTES.press)} element={<PressPage />} />
                    <Route
                        path={`${translate(ROUTES.unsubscribeBlog)}/:token`}
                        element={<UnsubscribeBlog />}
                    />
                    <Route
                        path="/forgot-password/:token"
                        element={<ForgetPasword />}
                    />
                    { topLevelDomain === COUNTRY_TOP_LEVEL_DOMAIN.coza && //Only for coza
                    <Route path={translate(ROUTES.carFinanceForm)} element={<CarFinanceForm />} />
                    }
                    <Route path="*" element={<Notfound />} />
                </Routes>
            </div>
            <WhatsAppChatBox />
        </Suspense>
    );
}

export default App;
