const commonArabic = {
    and: 'و',
    buy: 'شراء',
    sell: 'بيع',
    send: 'إرسال',
    finance: 'التمويل',
    about: 'حول',
    blogs: 'المدونات',
    blog: 'المدونة',
    callUsAt: 'اتصل بنا على',
    signIn: 'تسجيل الدخول',
    signUp: 'التسجيل',
    or: 'أو',
    insure: 'التأمين',
    enteremailAddress: 'أدخل عنوان البريد الإلكتروني',
    enterPassword: 'أدخل كلمة المرور',
    rememberMe: 'تذكرني',
    forgotPassword: 'نسيت كلمة المرور؟',
    carsAvailable: 'السيارات المتاحة الآن',
    cancel: 'إلغاء',
    enterFullName: 'أدخل الاسم الكامل',
    byClickingXButton:
        'بالنقر على زر {{name}}، فإنك توافق على شروط الاستخدام وسياسة الخصوصية لدينا',
    termOfUse: 'شروط الاستخدام.',
    privacyPolicy: 'سياسة الخصوصية.',
    alreadyhaveanAccount: 'هل لديك حساب بالفعل؟',
    howItWorks: 'كيف يعمل الأمر',
    howtoBuy: 'كيفية الشراء',
    howtoSell: 'كيفية البيع',
    exploreBlogs: 'استكشف المدونات',
    car: 'السيارة',
    search: 'البحث',
    Certified: 'معتمد',
    Inspected: 'فحص',
    ev: 'كهربائي',
    hybrid: 'هجين',
    phev: 'PHEV',
    petrol: 'بنزين',
    diesel: 'الديزل',
    carburant: 'وقود',
    other: 'آخر',
    automatic: 'تلقائي',
    pleaseprovideusyourdetails: 'يرجى تزويدنا بتفاصيلك',
    pleaseprovideusyourcardetails: 'يرجى تزويدنا بتفاصيل سيارتك',
    desiredPleaseprovideusyourcardetails:
        'يرجى تزويدنا بتفاصيل السيارة المطلوبة لك، حدد واحدة على الأقل.',
    phonenumberisnotvalid: 'رقم الهاتف غير صالح',
    internalError: 'خطأ داخلي. يرجى المحاولة مرة أخرى أو الاتصال بنا.',
    knowaboutyourcarbetterandgetevaluation:
        'تعرف على سيارتك بشكل أفضل واحصل على تقييم للحصول على صفقة أفضل.',
    cardetailssendSuccessfully: 'تم إرسال تفاصيل السيارة بنجاح.',
    thanyouforprovidingusdetail:
        'شكرًا لتزويدنا بالتفاصيل. سنعاود الاتصال بك قريبًا.',
    selectbrand: 'اختر علامة تجارية',
    selectcountry: 'حدد الدولة',
    enterMileage: 'حدد الدولة',
    minMileage: 'الحد الأدنى لعدد الكيلومترات',
    maxMileage: 'الحد الأقصى لعدد الكيلومترات',
    selectmodel: 'اختر نموذجًا',
    selectYear: 'اختر العام',
    brand: 'العلامة التجارية',
    model: 'الموديل',
    modelYear: 'سنة الصنع',
    selectFuelType: 'اختر نوع الوقود',
    selectACondition: 'اختر حالة.',
    phoneNumber: 'رقم الهاتف',
    loanTitle: 'نقدم لك أفضل عرض، احصل على 10% فائدة',
    loanHappyCusSubtitle:
        'نحن نهتم بزبائننا وسعادتهم مما يحفزنا على تقديم المزيد من الخدمات',
    loanButton: 'احسب قرضي',
    calculateButton: 'احسب',
    blogTitle: 'انظر قبل شراء السيارة',
    blogSubtitle:
        'هذه هي الأشياء التي يجب عليك معرفتها قبل شراء أي نوع من السيارات',
    viewAllCars: 'عرض جميع السيارات',
    availableNowOn: 'متاح الآن على',
    account: 'حساب',
    myAccount: 'حسابي',
    ourBrands: 'علاماتنا التجارية',
    contactUs: 'اتصل بنا',
    contactInformation: 'معلومات الاتصال.',
    aboutUs: 'معلومات عنا',
    addressLine: 'سطر العنوان',
    contact: 'اتصال',
    allcarshavebeenthoroughlyinspectedandreconditionedHowweguaranteecarquality:
        'تم فحص جميع السيارات وتجديدها بدقة. كيف نضمن جودة السيارة',
    imInterested: 'مرحبًا! أنا مهتم بـ.',
    buyOnline: 'شراء عبر الإنترنت',
    downPayment: 'الدفعة الأولى',
    hotDeal: 'صفقة ساخنة',
    lowPrice: 'سعر منخفض',
    likeNew: 'مثل الجديد',
    moneybackSubtitle: 'إذا لم يعجبك ذلك، استرد أموالك!',
    warrentyTitle: 'ضمان لمدة 6 أشهر',
    warrentySubtitle:
        'قابلة للتمديد إلى 12 شهرا على المحرك وعلبة التروس ومجموعة نقل الحركة',
    inspectionPointsSubtitle:
        'تم فحصها من قبل فريقنا من خبراء السيارات من جميع الزوايا',
    roadSideAssistanceTitle: '1 سنة المساعدة',
    roadSideAssistanceSubtitle:
        'خدمة المساعدة على الطريق مشمولة في جميع سياراتنا، لمدة عام واحد',
    deliveryTitle: 'خدمة التوصيل للمنزل',
    deliverySubtitle: 'يمكننا توصيل سيارتك إلى أي مكان في ساحل العاج',
    Summary: 'ملخص',
    engine: 'محرك',
    enginePower: 'محرك',
    fuelType: 'نوع الوقود',
    bodyType: 'نوع الهيكل',
    numberofkeys: 'عدد المفاتيح',
    exteriorColor: 'اللون الخارجي',
    interiorColor: 'اللون الداخلي',
    engineSize: 'حجم المحرك',
    fiscalPower: 'السلطة المالية',
    specifications: 'المواصفات',
    lengthWidthHeight: 'الطول والعرض والارتفاع',
    weight: 'ثقل',
    emissionCO2: 'انبعاث CO2',
    fuelConsumption: 'استهلاك الوقود',
    enginepower: 'قوة المحرك',
    fuelTankCapacity: 'سعة خزان الوقود',
    'performance&Economy': 'الأداء والاقتصاد.',
    features: 'ملامح',
    'weight&Dimensions': 'الوزن والأبعاد',
    showMoreFeatures: 'عرض المزيد من الميزات',
    showLess: 'عرض أقل',
    serviceHistory: 'سجل الخدمة',
    whyAUTO24: 'لماذا أوتو 24',
    serviceCenter: 'مركز الخدمة',
    Kilometers: 'كيلومتر',
    'lookingForaCar?tellUs': 'تبحث عن سيارة؟ أخبرنا!',
    sellPageTitle: 'بيع سيارتك بخطوتين سهلتين',
    sellPageSubtitle:
        'يمكنك تزويدنا بتفاصيل حول سيارتك وسنقوم بالاتصال بك لمزيد من الصفقات.',
    findCarPricePageTitle: 'ابحث عن سعر سيارتك',
    findCarPricePageSubtitle:
        'يمكنك تقديم تفاصيل حول سيارتك وسنعاود الاتصال بك لمزيد من الصفقات.',
    whyFinanceYourCarwithAuto24: 'لماذا تموّل سيارتك مع AUTO24',
    financeSubtitle:
        'نقدم لك أفضل عروض التمويل بفائدة منخفضة ودفعات أولية قليلة',
    competitiveRates: 'أسعار تنافسية.',
    competitiveDesc: 'معدل النسبة السنوي يبدأ من 5٪ (إرشادي)',
    competitiveDescMA: 'إمكانية الحصول على رصيد مجاني بشروط',
    flexiableDuration: 'فترات مرنة',
    flexiableDes: 'ادفع ثمن سيارتك على 12 إلى 72 قسطًا شهريًا',
    Percent100OnlineApproach: 'نهج عبر الإنترنت بنسبة 100٪',
    Percent100OnlineApproachDesc: 'قم بتوقيع جميع الوثائق في منزلك',
    loanCalculatorTitle: 'آلة حاسبة لقروض السيارات',
    loadCalculatorSubtitle:
        'قد تختلف أسعار الفائدة الشهرية حسب الشركات التمويلية المختلفة.',
    interestRate: 'معدل الفائدة',
    years: 'اعوام',
    ourFinancingPartners: 'شريك التمويل لدينا',
    ourInsurePartners: 'شريك التأمين لدينا',
    ourPartners: 'شركاؤنا',
    copyrightfooter:
        'حقوق الطبع والنشر © مجموعة أفريكار بي تي واي المحدودة - جميع الحقوق محفوظة',
    insureSubtitle: 'استمتع بأفضل وثيقة تأمين بأفضل سعر',
    aboutFinanceSub: 'إليك بعض شركاء التمويل لدينا',
    selectMileageRange: 'اختر نطاق المسافة المقطوعة',
    selectTransmissionType: 'اختر نوع ناقل الحركة',
    backToHome: '.العودة إلى الصفح',
    getACallBack: 'احصل على مكالمة هاتفية',
    personalDetails: 'تفاصيل شخصية',
    carDetails: 'تفاصيل السيارة',
    enterCarDetails: 'أدخل تفاصيل السيارة',
    ourBrandsSubtitle:
        'نحن مرتبطون بأفضل علامات تجارية للسيارات التي حازت على العديد من الجوائز لأفضل أداء',
    ourTeam: 'فرقنا',
    findCarPrice: 'أداة التسعير',
    teamMembers: 'أعضاء الفريق',
    ourTeamSubtitle: 'تعرف علينا بشكل أفضل من خلال أفكار فريقنا.',
    latestPosts: 'أحدث المنشورات',
    lastestPostSubtitle: 'هنا بعض المدونات الأخيرة من مستخدمين مختلفين',
    featuredPosts: 'منشورات مميزة',
    featuredPostSubtitle: 'هنا توجد مدونات مميزة من مستخدمين مختلفين.',
    newsLetterTitle: 'لا تفوت قصة',
    newsLetterSubtitle:
        'اشترك في مدونة AUTO24 للبقاء على اطلاع بكل شيء عن السيارات.',
    buySellTitle: 'هل أنت مستعد؟',
    buySellSubtitle:
        'سواء كان بيع سيارتك المستعملة أو شراء رحلتك التالية، يمكننا القيام بكل شيء.',
    sellYourCar: 'بيع سيارتك',
    buyaCar: 'شراء سيارة',
    footerInfo:
        'AUTO24.ci هي شركة تابعة لشركة Africar Group Pty Ltd، مسجلة في ساحل العاج بموجب رقم التعريف الضريبي CI-2022-0027564 O.',
    FAQ: 'أسئلة شائعة',
    buyCars: 'شراء السيارات.',
    sellCars: 'بيع السيارات.',
    categories: 'الفئات',
    topStories: 'القصص الشائعة.',
    pleaseInputYourPassword: 'الرجاء إدخال كلمة المرور الخاصة بك!',
    donthaveanaccount: 'ليس لديك حساب؟ سجل الآن',
    resetPassword: 'إعادة تعيين كلمة المرور',
    editProfile: 'تعديل الملف الشخصي',
    createNewPassword: 'إنشاء كلمة مرور جديدة',
    passwordresetsuccessfully: 'تمت إعادة تعيين كلمة المرور بنجاح',
    pleaseInputYourName: 'الرجاء إدخال اسمك!',
    pleaseInputYourEmail: 'يرجى إدخال عنوان البريد الإلكتروني الخاص بك!',
    pleaseInputValidEmail: 'يرجى إدخال عنوان بريد إلكتروني صالح!',
    pleaseInputYourPhoneNumber: 'الرجاء إدخال رقم هاتفك!',
    maximumXdigitsisallowed: 'الحد الأقصى المسموح به {{X}} رقم',
    minimumXdigit: 'يسمح بأرقام {{X}} كحد أدنى',
    passwordisRequired: 'كلمة المرور مطلوبة!',
    minimum8chars: 'مطلوب 8 أحرف كحد أدنى',
    usersuccessfullyregistered: 'تم تسجيل المستخدم بنجاح!',
    shortBio: 'سيرة ذاتية قصيرة',
    Save: 'حفظ',
    doyouwantDeleteAccount: 'هل ترغب في حذف حسابك نهائيًا؟',
    delete: 'حذف',
    saveChanges: 'حفظ التغييرات.',
    youcanstillchangetheseinformation: 'يمكنك تغيير هذه المعلومات بعد ذلك.',
    changessavedsuccessfully: 'يمكنك تغيير هذه المعلومات بعد ذلك.',
    yourchangessavedsuccessfully: 'تم حفظ التغييرات بنجاح.',
    deleteyourAccount: 'هل ترغب في حذف حسابك؟',
    deleteAccountsubtitile:
        'عند حذف حسابك، ستفقد جميع بياناتك ولن تتمكن من الوصول إلى حسابك.',
    accountdeletedsuccessfully: 'تم حذف الحساب بنجاح.',
    youcanstillexpolrecarwithouthavinganaccount:
        'يمكنك استكشاف السيارات بدون الحاجة إلى حساب.',
    logout: 'تسجيل الخروج',
    xInspectionPoints: '{{number}} نقطة فحص تم فحص, جميع سياراتنا بعناية شاملة',
    xlistingPageResultTitle: ' تم العثور على عدد {{number}} من السيارات.',
    xlistingPageResultsTitle: ' تم العثور على عدد {{number}} من السيارات.',
    sortBy: 'ترتيب حسب:',
    filterBy: 'مصنف بواسطة:',
    lowestPriceFirst: 'السعر الأدنى أولاً',
    highestMileageFirst: 'المسافة المقطوعة الأعلى أولاً',
    lowestMileageFirst: 'المسافة المقطوعة الأدنى أولاً',
    price: 'السعر',
    mileage: 'المسافة المقطوعة',
    selectBodyType: 'حدد نوع الهيكل',
    filter: 'مرشحات',
    moreFilters: 'مرشحات إضافية',
    color: 'اللون',
    transmission: 'نوع الناقل',
    highestPriceFirst: 'السعر الأعلى أولاً',
    provideUsYourDetail: 'يرجى تزويدنا بتفاصيلك',
    interestcartitle: 'هل ترغب في شراء هذه السيارة؟',
    interestcarsubtitle:
        'اختر خيارًا واحدًا، وسيظل الخيارات الأخرى متاحة في عملية الشراء الخاصة بك.',
    bookThisCar: 'احجز هذه السيارة.',
    bookThisCarSubtitle:
        'انقر هنا لإرسال دفعة 3000 درهم ، سنقوم بحظر السيارة لمدة 1 أسبوع ، فقط من أجلك ',
    scheduleAVisit: 'جدولة زيارة.',
    scheduleAVisitSubtitle:
        'قم بزيارة متجرنا للاطلاع على السيارة التي ترغب فيها. ستظل متاحة للمشترين الآخرين.',
    popularblog: '.المدونات الشائعة.',
    popularblogSub: 'فيما يلي مدونات شائعة من مستخدمين مختلفين',
    category: 'الفئة.',
    carOverview: 'نظرة عامة على السيارة',
    Detailssentsuccessfully: 'تم إرسال التفاصيل بنجاح.',
    DetailChangeSubtitle:
        'شكرا لك على تزويدنا بالتفاصيل الخاصة بك. سنعاود الاتصال بك قريبا.',
    continue: 'استمرار',
    carnotfoundSubtitle:
        'لا تتوفر سيارة وفقًا للمعايير المحددة. ساعدنا بتقديم تفاصيلك وسنساعدك في العثور على السيارة المناسبة!',
    logoutModelTitle: 'هل أنت متأكد من أنك تريد تسجيل الخروج؟',
    french: 'French',
    QuickLinks: 'روابط سريعة',
    similarResults: 'نتائج مماثلة',
    copyrightInfo:
        'AUTO24.CI هي شركة تابعة لمجموعة أفريكار بي تي واي المحدودة ، مسجلة تحت رقم التعريف الفريد CI-2022-0027564 O في جمهورية كوت ديفوار',
    recentlyAdded: 'تمت إضافتها مؤخرًا',
    recentlyAddedSubtitle:
        'هنا يمكنك رؤية السيارات المختلفة التي تمت إضافتها مؤخرًا',
    newCarList: 'السيارات الجديدة',
    newCarListSubtitle: 'هنا يمكنك رؤية سيارات جديدة مختلفة',
    electricCarList: 'السيارات الكهربائية',
    electricCarListSubtitle: 'هنا يمكنك رؤية مختلف السيارات الكهربائية',
    importedCarList: 'السيارات المستوردة',
    importedCarListSubtitle: 'هنا يمكنك رؤية مختلف السيارات المستوردة',
    cheapestCarList: '{{X}} السيارات بأقل من',
    cheapestCarListSubtitle:
        'هنا يمكنك رؤية مختلف السيارات الرخيصة بأقل {{X}} من',
    hereyoucanseedifferentsimilarcars: 'هنا يمكنك رؤية سيارات مختلفة مماثلة',
    carNotFound: 'لم يتم العثور على السيارة.',
    whatsappUrlText: 'مرحبا! أنا مهتم بهذه السيارة',
    latestService: 'أحدث الخدمات',
    resetSuccessMsg:
        'يمكنك الآن تسجيل الدخول باستخدام كلمة المرور الجديدة أو لا يزال بإمكانك استكشاف السيارات',
    bothPasswordMustMatch: 'يجب أن تتطابق كلمتا المرور',
    mustBeAtleast8Characters: 'يجب أن تتكون من 8 أحرف على الأقل',
    sendResetLink: 'إرسال رابط إعادة التعيين',
    returnTo: 'العودة إلى.',
    enterEmailToGetResetLinkInfo:
        'أدخل البريد الإلكتروني المرتبط بحسابك وسنرسل لك رابطًا عبر البريد الإلكتروني لإعادة تعيين كلمة المرور',
    resetPasswordModalTitle:
        'هل أنت متأكد من أنك تريد إعادة تعيين كلمة المرور الخاصة بك؟',
    yourPasswordChangedSuccessfully: 'تم تغيير كلمة المرور بنجاح.',
    confirmPassword: 'تأكيد كلمة المرور',
    carViewsXTimes: 'عرض {{number}} مرات اليوم.',
    shortDescription: 'وصف قصير',
    carImages: 'صور السيارة',
    uploadImage: 'تحميل الصورة',
    browse: 'تصفح',
    listAnother: 'قم بإدراج أخرى.',
    listCarRequestSentSucessfully: 'تم إرسال طلب قائمة السيارة بنجاح.',
    basicDetails: 'التفاصيل الأساسية',
    manual: 'يدوي',
    postYouHaveLiked: 'المشاركة التي قمت بالإعجاب بها',
    enterOldPassword: 'أدخل كلمة المرور القديمة',
    enterNewPassword: 'كلمة المرور الجديدة',
    confirmNewPassword: 'تأكيد كلمة المرور الجديدة',
    passwordChangeSuccessfully: 'تم تغيير كلمة المرور بنجاح.',
    verificationTitle: 'لقد أرسلنا لك رابط التحقق إلى عنوان بريدك الإلكتروني',
    viewLink: 'عرض الرابط.',
    resendingLink: 'إعادة إرسال الرابط.',
    tokenExpired: 'Your token is expired please create new one',
    tokenExpiredTitle: 'Account Verification Link Expired',
    callBackSubtitle: 'أدخل اسمك ورقم هاتفك وسنتصل بك قريبًا',
    provideUsYourDetails: 'يرجى تزويدنا بتفاصيلك',
    showCars: 'عرض السيارات',
    accountVerifiedSuccessfully: 'تم التحقق من الحساب بنجاح',
    accountVerifiedSuccessfullySubtitle:
        'تم التحقق من حسابك بنجاح. هل تريد تسجيل الدخول؟',
    engineSound: 'صوت المحرك',
    showAllImage: 'عرض جميع الصور.',
    ourBenefits: 'فوائدنا.',
    inspectionPoints: '100 نقطة فحص من خبراء السيارات لدينا.',
    warranties: 'ضمان لمدة 6 أشهر (المحرك وصندوق التروس والمحور).',
    maintenances: '1 سنة حزمة الصيانة مع أوتوفاست من قبل CFAO',
    assistance: 'قطرة وسحب لمدة 12 شهرًا للمساعدة على الطريق والتعطل.',
    multiCurrency: 'الدفع بعملات متعددة DH ، € ، $ ، £.',
    moneyBack: 'ضمان استعادة الأموال خلال 5 أيام.',
    financing: 'التمويل ',
    dragAndDrop: 'اسحب وأسقط الصورة أو تصفح',
    imageFormat: 'الصيغ المدعومة: JPEG ، PNG',
    sellNow: 'بيع الآن',
    confirm: 'تأكيد.',
    didntFindQuestion: 'هل لم تجد السؤال الذي تبحث عنه؟',
    yourMessage: 'رسالتك.',
    sendMessage: 'ارسل رسالة',
    loanTenure: 'مدة القرض',
    selectLoanTenure: 'حدد مدة القرض',
    interestInput: 'أدخل المبلغ بين ({{X}} و {{Y}})',
    months: 'أشهر',
    from: 'من',
    upto: 'حتى',
    rateOfInterest: 'سعر الفائدة.',
    tenure: 'المدة.',
    monthlyEmi: 'الأقساط الشهرية.',
    loadMore: 'تحميل المزيد.',
    contactSubtitle: 'هل لديك أي استفسارات؟ يمكنك مراسلتنا بحرية.',
    inspector: 'مفتش',
    inspectorSubtitle: 'اطلب مفتشًا',
    insurance: 'تأمين',
    insuranceSubtitle: 'اطلب أفضل تأمين',
    financeSubtitles: 'اطلب أفضل تمويل',
    diagnostic: 'تشخيص',
    minPrice: 'أدنى سعر',
    maxPrice: 'أقصى سعر',
    minDistance: 'الحد الأدنى للمسافة.',
    min: 'الحد الأدنى',
    max: 'أقصى',
    maxDistance: 'أقصى مسافة',
    diagonosticSubtitle: 'اطلب تشخيصًا',
    VINVerify: 'تحقق من الرقم التعريفي للمركبة (VIN)',
    VINVerifySubtitle: 'اطلب التحقق السريع للرقم التعريفي للمركبة',
    learnMore: 'تعلم المزيد',
    speedRegular: 'منظم السرعة',
    airCondition: 'مكيف الهواء',
    sequential: 'متسلسله',
    CDPlayer: 'مشغل اسطوانات',
    reversingCamera: 'عكس الكاميرا',
    reversingRadar: 'رادار عكسي',
    GPSNavigation: 'نظام تحديد المواقع والملاحة',
    parkAssist: 'مساعد الركن',
    startAndStop: 'بدء وإيقاف',
    airbag: 'وسادة هوائية',
    ABS: 'القيمه المطلقه',
    computer: 'حاسوب',
    rims: 'الحافات',
    sunroof: 'سقف الشمس',
    electricMirrors: 'مرايا كهربائية',
    electricWindows: 'نوافذ كهربائية',
    dateFirstUse: 'تاريخ الاستخدام الأول',
    seats: 'مقاعد',
    certifiedUsedCars: 'سيارات مستعملة معتمدة',
    whyChooseAuto: 'لماذا تختار AUTO24؟',
    certifiedUsedCarsSubtitle: 'شراء السيارات المستعملة بثقة وشفافية',
    inpectionsPoints: '100 نقطة فحص',
    inpectionsPointsSubtitle: 'تم فحص جميع سياراتنا بعناية شاملة',
    refundPolicy: 'سياسة استرداد لمدة 5 أيام',
    refundPolicySubtitle: 'إذا لم يعجبك ذلك، استرد أموالك!',
    warrentySubtitles: 'المحرك وصندوق التروس ونظام القوة، ضمان لمدة 6 أشهر',
    excellentCondition: 'حالة ممتازة',
    goodCondition: 'حالة جيدة',
    averageCondition: 'متوسط الحالة',
    damagedCar: 'السيارة المتضررة',
    lessThan10: 'اقل من 10000 كم',
    betweenXAndY: 'بين {{X}} و {{Y}} كم',
    moreThan100: 'اكثر من 100 000 كم',
    serviceTitle: 'تشتري أو تبيع؟ يمكننا مساعدتك',
    serviceSubtitle: 'استكشف خدماتنا واجعل تجربة شراء / بيع السيارة لديك سلسة ',
    verifyAccount: 'التحقق من الحساب.',
    verifyNumber: 'تحقق من الرقم.',
    otpVerificationSubtitle:
        'أدخل رمز الخمسة أرقام المرسلة إلى. {{code}} {{number}}',
    otpVerificationEmail: 'أدخل رمز الخمسة أرقام المرسلة إلى. {{email}}',
    didntGetTheCode: 'لم تتلقَ الرمز؟',
    resendCode: 'إعادة إرسال الرمز.',
    bluetooth: 'بلوتوث',
    home: 'الصفحة الرئيسية.',
    lookingForBestInsurance: 'هل تبحث عن أفضل تأمين؟',
    ourCustomerRepresentativesWillGetBackInTouchWithYou:
        'سيتصل بك ممثلو العملاء لدينا قريبًا.',
    upload: 'رفع',
    profileUpload: 'تحميل صورة جديدة',
    remove: 'إزالة',
    xIsrequired: '{{name}} مطلوب.',
    number: 'رقم',
    backToCarDetail: 'العودة إلى صفحة التفاصيل',
    backToTheCarDescription: 'العودة إلى وصف السيارة',
    backToTheCarListing: 'العودة إلى قائمة السيارات',
    phoneVerifiedSuccesfully: 'تم التحقق من الهاتف بنجاح',
    phoneVerifiedSubtitle: 'تم التحقق من رقم هاتفك بنجاح. هل تريد تسجيل الدخول',
    signWithPhone: 'تسجيل الدخول باستخدام الهاتف',
    signWithEmail: 'تسجيل الدخول باستخدام البريد الإلكتروني',
    newPriceFrom: 'سعر السيارة الجديدة من',
    theySpeakAboutUs: 'تتحدث عنا',
    theySpeakAboutUsSubTitle:
        'مجموعة مختارة من زعماء الصحافة الدولية يتحدثون عن AUTO24',
    carLoanSubTitle: 'الدفع الشهري التقريبي',
    carLoanTotalRepayment: 'إجمالي مبلغ الدفع',
    forgotTokenExpired: 'انتهت صلاحية رابط إعادة تعيين كلمة المرور',
    discover: 'اكتشف السيارة',
    paperWork: 'أوراق المركبات محدثة ومثبتة',
    verifyhistory: 'نتحقق من تاريخ جميع المركبات التي نشتريها',
    buyBack: 'نعيد شراء سيارتك القديمة',
    additionalPhotos: 'صور إضافية',
    technicalVisit: 'شهادة الزيارة الفنية',
    keysPhotos: 'صورة المفاتيح',
    seeKeyPhotos: 'انظر الصورة',
    pleaseTryAfterSomeTime: 'يرجى المحاولة بعد مرور بعض الوقت',
    favourites: 'المفضلة',
    electric: 'كهربائي',
    autonomy: 'الاستقلال الذاتي',
    productionYear: 'سنة الإنتاج',
    payWithOtherCurrencytitle: 'ادفع بعملة أخرى باستخدام AUTO24',
    payWithOtherCurrencySubtitle:
        'مع AUTO24 ، يمكنك الدفع على أحد حساباتنا المصرفية الدولية بأي من العملات أدناه. هذا هو السعر الذي ستحتاج إلى نقله.',
    convertedPrice: 'السعر المحول',
    favouritesNotFoundSubtitle:
        'لم تقم بإضافة أي سيارات إلى المفضلة. استكشف السيارات وأضف مفضلاتك',
    noFavouritesFound: 'لم يتم العثور على مفضلات',
    frontCrankOilSeal: 'ختم زيت كرنك أمامي',
    engineOverHaul: 'إصلاح المحرك',
    rearCrankOilSeal: 'ختم زيت الكرنك الخلفي',
    engineTuneUp: 'ضبط المحرك',
    engineMount: 'جبل المحرك',
    oilPanGasket: 'طوقا عموم النفط',
    valveCoverGasket: 'طوقا غطاء الصمام',
    oilFilterBracketOring: 'قوس فلتر الزيت oring',
    intakeManifoldGasket: 'طوقا مشعب السحب',
    waterLeak: 'تسرب المياه',
    radiator: 'مشعاع',
    engineTimingCoverGasket: 'طوقا غطاء توقيت المحرك',
    waterPump: 'مضخة مياه',
    belts: 'احزمه',
    fuelFilter: 'فلتر الوقود',
    airFilter: 'فلتر الهواء',
    underChassis: 'تحت الهيكل',
    tieRod: 'قضيب التعادل',
    ballJointLandR: 'الكرة المشتركة L &R',
    idlerArm: 'idler Arm',
    pitmanArm: 'ذراع بيتمان',
    steeringPump: 'مضخة التوجيه',
    steeringHoses: 'خراطيم التوجيه',
    steeringBox: 'صندوق التوجيه',
    steeringRackAndPinion: 'رف التوجيه والترس',
    frontShockLandR: 'صدمة أمامية L &R',
    rearShockLandR: 'الصدمة الخلفية L &R',
    stabilizerLinkage: 'ربط المثبت',
    crossJointPropellerShaft: 'عبر المشتركة المروحة رمح',
    upperArmBushing: 'جلبة الذراع العلوية',
    rearLowerArmBushing: 'جلبة الذراع السفلية الخلفية',
    suspensionSpring: 'تعليق الربيع',
    breakSystem: 'نظام الاستراحة',
    brakePad: 'وسادة الفرامل',
    brakeDiscRotor: 'دوار قرص الفرامل',
    wheelBearing: 'wheel Bearing',
    brakeMasterCLY: 'الفرامل ماستر CLY',
    backBooster: 'داعم الظهر',
    brakeWheelCall: 'استدعاء عجلة الفرامل',
    handBrakeCable: 'كابل فرامل اليد',
    brakeHoses: 'خراطيم الفرامل',
    rearAxlesAndDriveShafts: 'المحاور الخلفية وأعمدة الإدارة',
    frontAxle: 'المحور الأمامي',
    rearAxle: 'المحور الخلفي',
    axleDifference: 'فرق المحور',
    differenceFrontOrRear: 'الفرق الأمامي أو الخلفي',
    axleSealFrontOrRear: 'ختم المحور الأمامي أو الخلفي',
    oilPumpGasket: 'طوقا مضخة النفط',
    frontOilSeal: 'ختم الزيت الأمامي',
    rearOilSeal: 'ختم الزيت الخلفي',
    transmissionMount: 'حامل ناقل الحركة',
    gearStickSouta: 'عصا التروس سوتا',
    oilCollerPipe: 'أنبوب تبريد الزيت',
    gearPerformance: 'أداء التروس',
    liquidSystem: 'نظام السائل',
    engineOil: 'زيت المحرك',
    transmissionOil: 'زيت ناقل الحركة',
    brakeFluid: 'سائل الفرامل',
    engineCoolant: 'سائل تبريد المحرك',
    washerFluid: 'سائل الغسالة',
    electricitySystem: 'نظام الكهرباء',
    battery: 'بطارية',
    alternator: 'المولد',
    starler: 'ستارلر',
    lights: 'اضواء',
    ac: 'مكيف الهواء',
    centerLock: 'قفل مركزي',
    wiperBlades: 'شفرات المساحات',
    troubleCode: 'رمز المتاعب',
    computerCods: 'كود الكمبيوتر',
    acCondensor: 'مكثف مكيف الهواء',
    acRadiator: 'مشعاع مكيف الهواء',
    interior: 'داخل',
    seatAndBelts: 'المقاعد والأحزمة',
    interiorControlsAndSwitches: 'الضوابط الداخلية والمفاتيح',
    sunRoofAndWindows: 'سقف الشمس والنوافذ',
    fuelAndTemperatureGauge: 'مقياس الوقود ودرجة الحرارة',
    dashboardAndGauges: 'لوحة القيادة والمقاييس',
    radioOrMusicSystem: 'راديو أو نظام موسيقى',
    sRSAirbags: 'وسائد هوائية SRS',
    steeringWheelTiltOrlock: 'إمالة عجلة القيادة أو قفلها',
    mirrors: 'المرايا',
    bodyInspection: 'فحص الجسم',
    inspectionDate: 'تاريخ التفتيش',
    carInspectionReport: 'تقرير فحص السيارة',
    showPicture: 'اظهار الصورة',
    pageNotFound: 'عفوًا! الصفحة غير موجودة.',
    pageRemoved: 'هذه الصفحة غير موجودة أو تمت إزالتها!',
    askQuestionOnWhatsapp: 'الاسئله؟ اسألنا على الواتس اب!',
    whatsappsPlaceholder: 'اسأل أي شيء!',
    customerSupport: 'دعم العملاء',
    needHelpChatWithUs: 'هل تحتاج إلى مساعدة؟ دردش معنا على الواتساب',
    instaTitle: 'تابعنا على Instagram',
    instaSubTitle:
        'ابقَ على اطلاع بآخر العروض والمنتجات والأخبار لدينا على الفور!',
    questionSentSuccessfully: 'تم إرسال السؤال بنجاح.',
    questionSentSuccessfullySubtitle:
        'تم إرسال سؤالك بنجاح. سنعاود الاتصال بك للحصول على إجابتك',
    unSubscribeMessage: 'لقد تم إلغاء اشتراكك بنجاح من مدونات Auto24.',
    numberMustBeUnique: 'يجب أن يكون الرقم فريدا',
    pleaseEnterFullName: 'الرجاء ادخال الاسم الكامل',
    saving: 'حفظ',
    sending: 'ارسال',
    signingIn: 'تسجيل الدخول',
    signingUp: 'التسجيل',
    loggingout: 'تسجيل الخروج',
    deleting: 'حذف',
    querySubmittedSuccessfully: 'تم إرسال الاستعلام بنجاح',
    somethingWentWrong: 'حدث خطأ ما!',
    removedFromWishlist: 'تمت إزالتها من القائمة المفضلة',
    addedToWishlist: 'تمت الإضافة إلى القائمة المفضلة',
    subscribedSuccessfully: 'تم الاشتراك بنجاح',
    alreadySubscribed: 'مشترك بالفعل',
    messageIsRequired: 'الرسالة مطلوبة',
    fullName: 'الاسم الكامل',
    phoneNumberVerifiedSuccess:
        'تم التحقق من رقم الهاتف بنجاح ، يرجى تسجيل الدخول.',
    verifyEmail:
        'يرجى التحقق من بريدك الإلكتروني لـ Auto24، تم إرسال رابط التحقق إلى بريدك الإلكتروني',
    sold: 'مُباع',
    new: 'جديد',
    used: 'مستخدم',
    none: 'لا أحد',
    all: 'جميع',
    disount: 'تخفيض',
    year: 'سنة {{X}} وما فوق',
    careers: 'وظائف',
    discountOff: '{{X}} تخفيض',
    financeYourCarWith: 'تمويل سيارتك مع',
    creditButton: '% 0 قرض',
    loanPaymentSimulator: 'محاكاة سداد القروض',
    vehiclePrice: 'سعر السيارة',
    noOfMonthlyPayments: 'عدد الدفعات الشهرية',
    monthlyPaymentAmount: 'مبلغ الدفع الشهري',
    creditRequestedAmount: 'مبلغ الائتمان المطلوب',
    overallCostOfCredit: 'التكلفة الإجمالية للتأمين',
    simulatorContribution: 'مساهمة المحاكاة',
    percentageOfContribution: 'نسبة المساهمة',
    applicationFees: 'تكلفة الملف',
    monthlyInsurance: 'التأمين الشهري',
    noOfOwners: 'عدد الملاك',
    condition: 'حالة',
    maisonMere: 'المقر الرئيسي',
    imported: 'مستورد',
    tamperedOdometer: 'عداد المسافات العبث',
    carIssue: 'قضايا السيارات',
    evaluateAgain: 'قيّم مرة أخرى',
    marketValue: 'قيمة السوق',
    downloadAuto24App: 'قم بتحميل تطبيق Auto24',
    browseMore: `تصفح، اشترِ، وبيع بسهولة - رفيقك النهائي في السيارات`,
    saveTimeAndEffort: 'وفّر الوقت والجهد',
    saveTimeAndEffortSubtitle:
        'دعنا نوفر لك الوقت الثمين حيث نتولى كل شيء، مما يجعل البيع سهلاً وخالياً من التوتر',
    freeNoCost: 'مجاناً بدون تكلفة',
    freeNoCostSubtitle: `اترك التفتيش والتصوير وتكاليف المعاملات والإجراءات لنا - لقد غطينا ذلك`,
    competetivePrice: 'سعر تنافسي',
    competetivePriceSubtitle:
        'احصل على أفضل سعر بسهولة معنا؛ نحن ندير جميع التواصلات مع الشارين والاستفسارات',
    carTitle: `تفاصيل السيارة`,
    carDesc: `قدم لنا المعلومات عن سيارتك`,
    personalDetail: `تفاصيل شخصية`,
    personalDetailDesc: `قدم لنا المعلومات عنك"`,
    sellCarHere: `بيع السيارة`,
    sellCarHereDesc: `يمكنك الحصول على صفقة مثالية وسننشر سيارتك`,
    buySellUsedCar: `شراء وبيع السيارات المستعملة في`,
    auto24Press: `Auto24 الصحافة`,
    auto24PressDesc1: `تم التعرف على Auto24 في وسائل الإعلام المختلفة في جميع أنحاء العالم`,
    auto24PressDesc2: `كما تم عرض Auto24 في مؤتمرات في باريس/فرنسا، لشبونة/البرتغال، برشلونة/إسبانيا، سيدني/أستراليا، لندن/المملكة المتحدة، كيغالي/رواندا، بانكوك/تايلاند، أبيدجان/ساحل العاج، جوهانسبرج/جنوب أفريقيا، داكار/السنغال، نيروبي/كينيا، الدار البيضاء/المغرب…`,
    mediaExposure: `تعرضنا الإعلامي`,
    mediaExposureDesc: `أدناه اختيار من تعرضنا الإعلامي`,
    press: `الصحافة`,
    enterValidPhoneNumber: 'أدخل رقم هاتف صالح'
};

export default commonArabic;
