export const CAR_VALUATION_LOADING = 'CAR_VALUATION_LOADING';
export const CAR_VALUATION_SUCCESS = 'CAR_VALUATION_SUCCESS';
export const CAR_VALUATION_ERROR = 'CAR_VALUATION_ERROR';

export interface ICarValuationInitialType {
    loading: boolean;
    data: any;
    error: boolean;
}

// const object = {
//     status: false,
//     pricing: {
//         _id: 0,
//         minMileage: 234,
//         maxMileage: 234,
//         minPrice: 234,
//         maxPrice: 234,
//         data: {
//             _id: "6628b7708e8679e799c87a47",
//             brand: {
//                 _id: "65af386caef0c5753b0714cd",
//                 brand: "Audi",
//                 image: "1705982060038-892.webp",
//                 createdAt: "2024-01-23T03:54:20.234Z",
//                 updatedAt: "2024-01-23T03:54:20.234Z",
//                 slug: "audi",
//                 __v: 0
//             },
//             model: {
//                 _id: "65b0e52e4826db93ab98f92e",
//                 slug: "audi-test-model",
//                 model: "TEST MODEL",
//                 year: 2019,
//                 brand: "65af386caef0c5753b0714cd",
//                 createdAt: "2024-01-24T10:23:42.490Z",
//                 updatedAt: "2024-01-25T05:11:40.331Z",
//                 __v: 0
//             },
//             bodyType: {
//                 _id: "65af50dc56c37559a96b9a25",
//                 details: [{
//                     lang: "fr",
//                     bodyType: "SUV ",
//                     _id: "65af5101aef0c5753b0714d9"
//                 }, {
//                     lang: "en",
//                     bodyType: "Suv In English",
//                     _id: "65af5101aef0c5753b0714da"
//                 }, {
//                     lang: "sn",
//                     bodyType: "SUV ",
//                     _id: "65af5101aef0c5753b0714db"
//                 }],
//                 image: "1705988316525-503.webp",
//                 createdAt: "2024-01-23T05:38:36.598Z",
//                 updatedAt: "2024-01-23T05:39:13.188Z",
//                 slug: "suv",
//                 __v: 0
//             },
//             fuelType: "petrol",
//             price: 234,
//             year: 2022,
//             country: "AmericanSamoa",
//             mileage: 234,
//             transmission: "automatic",
//             engine: "2333",
//             image: "1713944432141-169.webp",
//             createdAt: "2024-04-24T07:40:32.158Z",
//             updatedAt: "2024-04-24T07:40:32.158Z",
//             __v: 0
//         }
//     }
// }
