const heroWolof = {
    searchBarTitle: "Yoon wu gaaw te yomb nguir jënd wala jaay ay autos",
    searchBarPlaceholder: "Settale auto bi nga bëg",
    willingToSellYourUsedCar: "Danga bëga jaay sa auto bu daw?",
    searchByCategory: "Sett si zenre",
    whyChooseAuto: "Why Choose AUTO24?",
    searchCarByTheirBodyType: "Sett si ni sen yaram mel",
    searhcarbybrands: "Sett si marqu autos yi",
    exploreCar: "Explore Cars",
    chooseCar: "Choose a Car",
    buyACar: "Buy your car",
    buyACarDesc: "Purchase it and relax, we'll take care of the rest!",
    buyACarDescs: "Buy safely and relax, we'll take care of everything!",
    weAreAroundTheArea: "Ñu ngui ci wettu Senegal",
    youcanfindusaroundthislocation: "Mën nga ñu fek ci wettu bërëp bii",
    SearchByLocation: "Sett ci gox",
    questionSubtitle: "Lii ay lacc yu wuute la jugge ci nitt yu wuute mën nga ñu ci xame",
    askQuestions: "Lacce ay lacc",
    questions: "Lacc",
    carsellInfo: "Give us details about your vehicle",
    yourCar: "Your Car",
    valuation: "Valuation",
    valuationInfo: "We come to inspect your car at your place or you come to us",
    sellCar: "Jaay",
    sellCarInfo: "Receive your money in less than 24 hours!",
    happycustomerHappyus: "Cliane bu contane, ñune tamite",
    questionTextAreaPlaceHolder: "Ban lacc nga bëga lacc?",
    discovercar: "Discover our cars",
    discovercarSubtitle: "Wërële ci suñu listu autos yu daw yuñu woolu",
    chooseACarSubtitle: "Select the car that suits you most",
    chooseACarSubtitles: "Tannale auto bi nga bëg"
}

export default heroWolof;