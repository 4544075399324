import { Dispatch } from 'redux';
import { $FIXME, carAvailableBrands, carBrands } from '../../constants';
import { Api } from '../Api';
import {
    FETCH_CAR_AVAILABLE_BRAND,
    FETCH_CAR_BRANDS_LOADING,
    FETCH_CAR_BRANDS_SUCCESS
} from '../types/carBrandsTypes';

export const getCarBrandsList = async (
    dispatch: Dispatch,
    wantAvailableBrands: boolean = false,
    params?: $FIXME
) => {
    dispatch({ type: FETCH_CAR_BRANDS_LOADING });
    const resData: any = wantAvailableBrands
        ? await getAvailableBrands()
        : await getCarAllBrands(params);
    if (resData.status === 200) {
        dispatch({
            type: !wantAvailableBrands
                ? FETCH_CAR_BRANDS_SUCCESS
                : FETCH_CAR_AVAILABLE_BRAND,
            payload: resData.data.carBrands
        });
    } else {
        // message.error(resData.response.data?.msg || 'Something went wrong');
    }
};

export const getCarAllBrands = async (params?: $FIXME) => {
    try {
        const result = await Api.get(`${carBrands}`, { params: { all: true } });
        return result;
    } catch (error) {
        return error;
    }
};
export const getAvailableBrands = async () => {
    try {
        const result = await Api.get(`${carAvailableBrands}`);
        return result;
    } catch (error) {
        return error;
    }
};

export const getBrandForPricingModuleByCountry = async (params?: $FIXME) => {
    try {
        const result = await Api.get(`/pricing/brands`, { params });
        return result;
    } catch (error) {
        return error;
    }
};