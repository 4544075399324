import { ALL_LANGUAGES, ROUTES } from "../../constants";

const routesEnglish = {
    [ROUTES.home]: ROUTES.slash,
    [ROUTES.slash]: ROUTES.slash,
    [ROUTES.buyCars]: ROUTES.buyCars,
    [ROUTES.buy]: ROUTES.buy,
    [ROUTES.sellCar]: ROUTES.sellCar,
    [ROUTES.finance]: ROUTES.finance,
    [ROUTES.carInsurance]: ROUTES.carInsurance,
    [ROUTES.about]: ROUTES.about,
    [ROUTES.faqs]: ROUTES.faqs,
    [ROUTES.contactUs]: ROUTES.contactUs,
    [ROUTES.blog]: ROUTES.blog,
    [ROUTES.account]: ROUTES.account,

    [ROUTES.category]: ROUTES.category,

    [ROUTES.privacyPolicy]: ROUTES.privacyPolicy,
    [ROUTES.termsOfUse]: ROUTES.termsOfUse,
    [ROUTES.unsubscribeBlog]: ROUTES.unsubscribeBlog,

    [ROUTES.editProfile]: ROUTES.editProfile,
    [ROUTES.sellYourCar]: ROUTES.sellYourCar,
}

const routesFrench = {
    [ROUTES.home]: "/fr",
    [ROUTES.slash]: "/fr",
    [ROUTES.buyCars]: "/fr/buy-cars",
    [ROUTES.buy]: "/fr/buy",
    [ROUTES.sellCar]: "/fr/sell-car",
    [ROUTES.finance]: "/fr/finance",
    [ROUTES.carInsurance]: "/fr/car-insurance",
    [ROUTES.about]: "/fr/about",
    [ROUTES.faqs]: "/fr/faqs",
    [ROUTES.contactUs]: "/fr/contact-us",
    [ROUTES.blog]: "/fr/blog",
    [ROUTES.account]: "/fr/account",

    [ROUTES.category]: "/fr/category",

    [ROUTES.privacyPolicy]: "/fr/privacy-policy",
    [ROUTES.termsOfUse]: "/fr/terms-of-use",
    [ROUTES.unsubscribeBlog]: "/fr/unsubscribe-blog",

    [ROUTES.editProfile]: "/edit-profile",
    [ROUTES.sellYourCar]: "/sell-your-car",
}

const routesArabic = {
    [ROUTES.home]: "/ar",
    [ROUTES.slash]: "/ar",
    [ROUTES.buyCars]: "/ar/buy-cars",
    [ROUTES.buy]: "/ar/buy",
    [ROUTES.sellCar]: "/ar/sell-car",
    [ROUTES.finance]: "/ar/finance",
    [ROUTES.carInsurance]: "/ar/car-insurance",
    [ROUTES.about]: "/ar/about",
    [ROUTES.faqs]: "/ar/faqs",
    [ROUTES.contactUs]: "/ar/contact-us",
    [ROUTES.blog]: "/ar/blog",
    [ROUTES.account]: "/ar/account",

    [ROUTES.category]: "/ar/category",

    [ROUTES.privacyPolicy]: "/ar/privacy-policy",
    [ROUTES.termsOfUse]: "/ar/terms-of-use",
    [ROUTES.unsubscribeBlog]: "/ar/unsubscribe-blog",

    [ROUTES.editProfile]: "/edit-profile",
    [ROUTES.sellYourCar]: "/sell-your-car",
}

const routesKinyarwanda = {
    [ROUTES.home]: "/rw",
    [ROUTES.slash]: "/rw",
    [ROUTES.buyCars]: "/rw/buy-cars",
    [ROUTES.buy]: "/rw/buy",
    [ROUTES.sellCar]: "/rw/sell-car",
    [ROUTES.finance]: "/rw/finance",
    [ROUTES.carInsurance]: "/rw/car-insurance",
    [ROUTES.about]: "/rw/about",
    [ROUTES.faqs]: "/rw/faqs",
    [ROUTES.contactUs]: "/rw/contact-us",
    [ROUTES.blog]: "/rw/blog",
    [ROUTES.account]: "/rw/account",

    [ROUTES.category]: "/rw/category",

    [ROUTES.privacyPolicy]: "/rw/privacy-policy",
    [ROUTES.termsOfUse]: "/rw/terms-of-use",
    [ROUTES.unsubscribeBlog]: "/rw/unsubscribe-blog",

    [ROUTES.editProfile]: "/edit-profile",
    [ROUTES.sellYourCar]: "/sell-your-car",
}

const routesZulu = {
    [ROUTES.home]: "/zu",
    [ROUTES.slash]: "/zu",
    [ROUTES.buyCars]: "/zu/buy-cars",
    [ROUTES.buy]: "/zu/buy",
    [ROUTES.sellCar]: "/zu/sell-car",
    [ROUTES.finance]: "/zu/finance",
    [ROUTES.carInsurance]: "/zu/car-insurance",
    [ROUTES.about]: "/zu/about",
    [ROUTES.faqs]: "/zu/faqs",
    [ROUTES.contactUs]: "/zu/contact-us",
    [ROUTES.blog]: "/zu/blog",
    [ROUTES.account]: "/zu/account",

    [ROUTES.category]: "/zu/category",

    [ROUTES.privacyPolicy]: "/zu/privacy-policy",
    [ROUTES.termsOfUse]: "/zu/terms-of-use",
    [ROUTES.unsubscribeBlog]: "/zu/unsubscribe-blog",

    [ROUTES.editProfile]: "/edit-profile",
    [ROUTES.sellYourCar]: "/sell-your-car",
}

const routesXhosa = {
    [ROUTES.home]: "/xh",
    [ROUTES.slash]: "/xh",
    [ROUTES.buyCars]: "/xh/buy-cars",
    [ROUTES.buy]: "/xh/buy",
    [ROUTES.sellCar]: "/xh/sell-car",
    [ROUTES.finance]: "/xh/finance",
    [ROUTES.carInsurance]: "/xh/car-insurance",
    [ROUTES.about]: "/xh/about",
    [ROUTES.faqs]: "/xh/faqs",
    [ROUTES.contactUs]: "/xh/contact-us",
    [ROUTES.blog]: "/xh/blog",
    [ROUTES.account]: "/xh/account",

    [ROUTES.category]: "/xh/category",

    [ROUTES.privacyPolicy]: "/xh/privacy-policy",
    [ROUTES.termsOfUse]: "/xh/terms-of-use",
    [ROUTES.unsubscribeBlog]: "/xh/unsubscribe-blog",

    [ROUTES.editProfile]: "/edit-profile",
    [ROUTES.sellYourCar]: "/sell-your-car",
}

const routesWolof = {
    [ROUTES.home]: "/sn",
    [ROUTES.slash]: "/sn",
    [ROUTES.buyCars]: "/sn/buy-cars",
    [ROUTES.buy]: "/sn/buy",
    [ROUTES.sellCar]: "/sn/sell-car",
    [ROUTES.finance]: "/sn/finance",
    [ROUTES.carInsurance]: "/sn/car-insurance",
    [ROUTES.about]: "/sn/about",
    [ROUTES.faqs]: "/sn/faqs",
    [ROUTES.contactUs]: "/sn/contact-us",
    [ROUTES.blog]: "/sn/blog",
    [ROUTES.account]: "/sn/account",

    [ROUTES.category]: "/sn/category",

    [ROUTES.privacyPolicy]: "/sn/privacy-policy",
    [ROUTES.termsOfUse]: "/sn/terms-of-use",
    [ROUTES.unsubscribeBlog]: "/sn/unsubscribe-blog",

    [ROUTES.editProfile]: "/edit-profile",
    [ROUTES.sellYourCar]: "/sell-your-car",
}

// For multiple languages
const getRoutesObj = (route: string) => ({
    [ROUTES.home]: `/${route}`,
    [ROUTES.slash]: `/${route}`,
    [ROUTES.buyCars]: `/${route}${ROUTES.buyCars}`,
    [ROUTES.buy]: `/${route}${ROUTES.buy}`,
    [ROUTES.sellCar]: `/${route}${ROUTES.sellCar}`,
    [ROUTES.finance]: `/${route}${ROUTES.finance}`,
    [ROUTES.carInsurance]: `/${route}${ROUTES.carInsurance}`,
    [ROUTES.about]: `/${route}${ROUTES.about}`,
    [ROUTES.faqs]: `/${route}${ROUTES.faqs}`,
    [ROUTES.contactUs]: `/${route}${ROUTES.contactUs}`,
    [ROUTES.blog]: `/${route}${ROUTES.blog}`,
    [ROUTES.account]: `/${route}${ROUTES.account}`,

    [ROUTES.category]: `/${route}${ROUTES.category}`,

    [ROUTES.privacyPolicy]: `/${route}${ROUTES.privacyPolicy}`,
    [ROUTES.termsOfUse]: `/${route}${ROUTES.termsOfUse}`,
    [ROUTES.unsubscribeBlog]: `/${route}${ROUTES.unsubscribeBlog}`,

    [ROUTES.editProfile]: `/${ROUTES.editProfile}`,
    [ROUTES.sellYourCar]: `/${ROUTES.sellYourCar}`,
})

const routesLang = {
    [ALL_LANGUAGES.english.value]: routesEnglish,  
    [ALL_LANGUAGES.french.value]: routesFrench, 
    [ALL_LANGUAGES.arabic.value]: routesArabic,  
    [ALL_LANGUAGES.kinyarnwanda.value]: routesKinyarwanda, 
    [ALL_LANGUAGES.zulu.value]: routesZulu, 
    [ALL_LANGUAGES.xhosa.value]: routesXhosa,
    [ALL_LANGUAGES.wolof.value]: routesWolof,
    
    // FOr others routes add like below note: don't use this method as it is giving issues
    // [ALL_LANGUAGES.xhosa.value]: getRoutesObj(ALL_LANGUAGES.xhosa.value), 
    // [ALL_LANGUAGES.wolof.value]: getRoutesObj(ALL_LANGUAGES.wolof.value),
  }
  
  export default routesLang;